@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
    --bg-color: #FAFAFD;
    --bg-white: #FFFFFF;
    --bg-color: #FFFFFF !important;
    --bg-Principal-color: #FAFAFD;
    --bg-gris: #c4ccde;
    --bg-gris-navbar: #EAEAEA;
    --bg-btn-accordion: #f8f8f8;
    --bg-accordion-Hover: #00917473;
    --bg-Foster-accordion: #F8F8F8;
    --bg-navbar: #1976D3;
    --bg-shadow: #cdcdcdc0;
    --bg-shadow-Navar: #2c2c2cc0;
    --bg-Icon: #cdcdcdc0;
    --bg-Icon-Hover: #009175;
    --bg-Hover: #cdcdcdc0;
    --bg-sidebar: #0E47A1;
    --color-text: #009175;
    --color-verde-menta: #009175;
    --bg-sidebar: linear-gradient(to right, #003048, #02293d, #012131, #01151f);
    --bg-sidebar2: linear-gradient(to right, #003048, #02293d, #012131, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f, #01151f);
    --bg-text-sidebar-hover: #14274a;
    --bg-text-sidebar-submenu-hover: #14274a;
    --color-text-hover: #009175;
    --color-titulo: #00917485;
    --color-text: #cdcdcdc0;
    --bg-nav-color: linear-gradient(to right, #081831, #081831, #081831, #060F20, #000000, #000000);
    --bg-general: #003048;

    --bg-cancelar: #e01c1c;
    --bg-canelar-hover: #ee3636 !important;
    --white-texto: #f0f0f0;
    --color-white: #F9F9F9;
    --bg-agua: #F3F5FA;
    --border-Grey: #d7d7d7;
    --border-Grey-2: #a6a6a6;
    --texto-blanco: linear-gradient(to right, #ffffff, #ffffff, #ffffff, #ffffff);
    /* --linear-Gradient: linear-gradient(to right, #A90F9E, #D41F94, #D41F94, #B806AC);*/
    --color-text-Content: #000000a6;
    --color-text-Content-White: #ffffffa6;
    --color-text: #000000;
    --color-text-Content-Dropdown: #000000d0;
    --border-Input: #b8b6b6;
    --border-Input-Focus: #6284d6;
    --bg-Tabs: #e1e1e1;
    --bg-Tabs-border: #d1d0d0d0;
    --bg-th: #e1e1e1;
    --bs-box-shadow: rgba(0, 0, 0, 0.866);

    --bg-btnEditar: #007bff;
    --bg-btnEditar-hover: #0056b3;

    --bg-btnEliminar: #dc3545;
    --bg-btnEliminar-hover: #bd2130;

    --bg-btnVer: #4caf50;
    --bg-btnVer-hover: #1b5e20;
    --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28%25255%25255%25255%2529%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e);
}

.bg {
    background-color: black !important;
}

.sinMB {
    margin-bottom: 0px !important;
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: 100% !important;
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
    padding-left: 33%;
    padding-right: 33%;
}

.custom-calendar {
    background-color: #f9f9f9;
    /* Fondo del calendario */
    font-family: 'Arial', sans-serif;
    height: 100%;
    /* Ocupar el 100% del espacio */
    width: 100%;
    /* Asegurarse que también ocupe el 100% en anchura */
}

.custom-calendar .rbc-btn-group {
    display: inline-block;
    white-space: nowrap;
    box-shadow: 1px 1px 3px 0px;
    border-radius: 5px;
    cursor: pointer;
}

.custom-calendar .rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    font-size: 16px;

}

.custom-calendar .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
    text-shadow: #000000;
    font-weight: 300;
    text-transform: uppercase;
    color: #000000;
}

.custom-calendar .rbc-off-range-bg {
    background: #cfcfcf;
}

.custom-calendar .rbc-month-header {
    display: flex;
    flex-direction: row;
    font-weight: 300;
    text-transform: uppercase;
    background-color: var(--bg-accordion-Hover) !important;
}

.custom-calendar .rbc-toolbar {
    background-color: var(--bg-accordion-Hover) !important;
    color: white;
    padding: 6px;

}

.custom-calendar .rbc-event {
    background-color: #57abe3;
    /* Color de eventos */
    color: black;
    padding: 2px;
    border-radius: 3px;
}

.custom-calendar .rbc-selected {
    background-color: var(--bg-accordion-Hover) !important;
}

.custom-calendar .rbc-time-view .rbc-time-gutter {
    background-color: #ecf0f1;
}

.custom-calendar .rbc-today {
    background-color: #c2f7d9 !important;
    /* Fondo del día de hoy */
}




* {
    margin: 0;
    padding: 0;

}

a h1 h2 h3 h4 h5 h6 p option {
    color: var(--color-text-Content) !important;
    font-size: 10px !important;
}

Label {
    font-size: 13px !important;
}

.font-size-login {
    font-size: 16px !important;
}

.letrapequeña {
    font-size: 9px !important;
    color: red;
}

#root {
    background: var(--bg-color);
}

.text-Enlaces {
    text-decoration: none;
}

.steps-section h1 h2 {
    font-weight: 700;

}

.banner-Clientes {
    height: 100vh !important;
    width: 100%;
    z-index: -1;
    clear: both;
    background-image: url('../assets/SVG/4662928_2450616\ \(1\).svg');
    background-repeat: unset;
    background-attachment: fixed;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center 20px;
}


.step_text1 {
    width: 50%;
    float: left;
    font-size: 55px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: var(--bg-general);
    font-weight: 600;
    text-align: left;

    padding-bottom: 6px;
    margin-left: 0px;
}

.step_text {
    width: 50%;
    float: left;
    font-size: 45px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: var(--bg-general);
    font-weight: 500;
    text-align: left;

    padding-bottom: 6px;
    margin-left: 40px;
}

.layout_padding2 {
    padding-top: 100px;
    padding-bottom: 50px;
}

body {
    color: var(--color-text-Content) !important;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-color);
    padding: 0 !important;
    overflow-x: hidden;

}

.swal-input-radio {
    text-align: left;
}

.swal2-radio label {
    display: block;
    margin-bottom: 8px;
}

/*==================================================            CSS MENU                 ==================================================*/
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba(255, 255, 255, 0.75)%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}



.NameNav {
    color: var(--color-text-Content-White) !important;
}

.navbar {
    background: var(--bg-sidebar2) !important;
    /* background-color: var(--bg-gris-navbar); */
    padding: 1rem, 0rem !important;
    border-bottom: 0px !important;
    margin-bottom: 3px;
    /*box-shadow: 1px 1px 6px 0px var(--bg-shadow-Navar);*/
    color: var(--color-text-Content-White);
    height: 44px;
}

.text-White-Nav {
    color: var(--color-text-Content-White);
}

.navbar-login {
    padding: 0rem, 0rem !important;
    border-radius: 0px !important;
    height: 54px;

}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0% !important;
    padding-left: 0px !important;
    padding-right: 12px !important;
}

.navbar-nav .dropdown-menu {
    width: auto !important;
    position: absolute !important;
    transform: translateX(7.8%) !important;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.15 s, opacity 0.15 s, transform 0.15 s;
    background-color: var(--bg-Principal-color);
    box-shadow: 1px 1px var(--bg-shadow);
    border: 1px solid var(--bg-gris);
    padding: 3px 5px;
}

.navbar:not(.navbar-nav-hover) .dropdown-menu.show {
    animation: show-navbar-dropdown 0.3s ease-in-out forwards;
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
}

.dropdown-item {
    color: var(--color-text-Content-Dropdown) !important;
    border-radius: 10px !important;
    transition: background-color 0.3s ease-in-out !important;

}

.dropdown-item a {
    text-decoration: none;
    color: var(--color-text-Content-Dropdown);
}

.dropdown-item:hover {
    background-color: var(--bg-Hover) !important;
}

.dropdowntoggles {
    padding: 2px 6px !important;
    border-radius: 3px;
    height: 32px !important;
    color: white;
}

.dropdowntoggles:hover {
    background-color: var(--bg-Hover) !important;
}

.profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
    border: 2px solid #ffffff8d;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

.Perfil {
    width: 100% !important;
    text-decoration: none;
}

.sidebar {

    width: 14rem !important;

    height: calc(100vh - 44px);

    /* box-shadow: 1px 2px var(--bg-shadow); */
    font-family: "Rubik", sans-serif;
    background: var(--bg-sidebar) !important;
    color: var(--color-text);
    font-weight: lighter !important;
    border-radius: 0px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    overflow-y: auto;
}

.sidebarContrac {
    width: 3.7rem !important;

    height: calc(100vh - 44px);

    /* box-shadow: 1px 2px var(--bg-shadow); */
    font-family: "Rubik", sans-serif;
    background-color: var(--bg-general) !important;
    color: var(--color-text);

    border-radius: 0px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    overflow-y: auto;
}

.ocultarbtnMenu {
    display: block !important;
}

.sidebar ul {
    list-style: none !important;
    padding: 4px !important;

}

.padtop {
    padding-top: 3px !important;
}

.sidebar ul li {
    margin-bottom: 5px;

}

Button ul {
    list-style: none !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin: 0 !important;
}



ul {
    padding-left: 4px !important;
}

Label ul .NoDecoration {
    list-style: none !important;

}

Label ul .NoDecoration {
    list-style: none !important;
    padding: 0px !important;
    margin: 0 !important;
    text-decoration: none !important;
    color: var(--color-text-Content) !important;
}



.NoDecoration {
    list-style: none !important;
    text-decoration: none !important;
    color: var(--color-white);

}

.NoDecoration2 {
    list-style: none !important;
    text-decoration: none !important;
    color: var(--color-text-Content) !important;

}

.NoDecoration3 {
    list-style: none !important;
    text-decoration: none !important;
    color: var(--color-white);

}

.text-link {
    text-decoration: none !important;
    color: var(--color-text-Content) !important;
}

.text-link:hover {
    color: var(--bg-btnEditar-hover) !important;
}

.text-link:hover {
    text-decoration: underline !important;
    color: var(--bg-btnEditar-hover) !important;
    cursor: pointer;
}

.tlink:hover {

    color: var(--color-verde-menta) !important;

}

.text-sidebar {
    width: 100%;
    text-decoration: none;
    color: var(--color-white);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out;
}

.text-sidebar::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    /* Inicialmente centrado */
    width: 0;
    height: 3px;
    background-color: var(--color-text-hover);
    /* Color del borde */

    transition: width 0.3s ease-out, left 0.3s ease-out;
    /* Animar ancho y posición */
}

.text-sidebar:hover::after {
    width: 100%;
    /* Expandir el borde */
    left: 0;
    /* Mover el borde hacia la izquierda */
    color: var(--color-text-hover);
}

.text-sidebar:hover {
    color: var(--color-text-hover);
    /* Cambiar color del texto */
}

.text-sidebar-subMenu {
    width: 100%;
    text-decoration: none;
    color: var(--color-white);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out;
    font-weight: lighter !important;
}

.text-sidebar-subMenu:hover {
    color: var(--color-text-hover);
    /* Cambiar color del texto */
}

.ms-1 {
    color: var(--color-white);
}

.text-sidebar:hover+.ms-1 {
    color: var(--color-text-hover);
    /* Cambia el color del texto y del icono al hacer hover */
}

.submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.text-Drop {
    width: 100%;
    text-decoration: none;
    color: var(--color-text-Content);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out;
}

.text-Drop::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    /* Inicialmente centrado */
    width: 0;
    height: 3px;
    background-color: var(--color-text-hover);
    /* Color del borde */

    transition: width 0.3s ease-out, left 0.3s ease-out;
    /* Animar ancho y posición */
}

.text-Drop:hover::after {
    width: 100%;
    /* Expandir el borde */
    left: 0;
    /* Mover el borde hacia la izquierda */
    color: var(--color-text-hover);
}

.text-Drop:hover {
    color: var(--color-text-hover);
    /* Cambiar color del texto */
}

.sidebar li div span {
    cursor: pointer;
}

.sidebar li div ul {
    padding: 4px;
}

.submenu-animation {
    max-height: 0 !important;
    overflow: hidden !important;
    transition: max-height 1.3s ease-in-out !important;
}

.submenu-animation.active {
    max-height: 500vh !important;
    /* Ajusta la altura máxima al 100% del alto de la ventana */
    transition: max-height 0.5s ease-in-out !important;
}


li .text-sidebar-submenu {
    width: 100%;
    text-decoration: none;
    color: var(--color-white);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out;
}

.text-sidebar-submenu::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    /* Inicialmente centrado */
    width: 0;
    height: 3px;
    background-color: var(--color-text-hover);
    /* Color del borde */

    transition: width 0.3s ease-out, left 0.3s ease-out;
    /* Animar ancho y posición */
}

.text-sidebar-submenu:hover::after {
    width: 100%;
    /* Expandir el borde */
    left: 0;
    /* Mover el borde hacia la izquierda */
    color: var(--color-text-hover);
}

.text-sidebar-submenu:hover {
    color: var(--color-text-hover);
    /* Cambiar color del texto */
}

.sizeIcon {
    width: 20px !important;
    height: 20px !important;

}

.sizeIcon2 {
    margin-top: -5px !important;
    width: 20px !important;
    height: 20px !important;

}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.hidden {

    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
}

.content {
    width: calc(100% - 16rem);
    /* border-radius: 10px !important; */
    padding: 3px 1px 0px 1px;
    background-color: var(--bg-color);

    border-top-left-radius: 10px !important;
}

.fondoAzul {
    background-color: #012131;
}

.Divcontenedor {
    background-color: var(--bg-color);
    /* border: 1px solid red; */
    /*box-shadow: 1px 1px var(--bg-shadow);  */
    border-radius: 10px !important;
    height: calc(100vh - 49px);
    overflow-y: auto !important;
    overflow-x: hidden !important;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    opacity: 1;
    transition: opacity 1s ease;
}

.border-all {
    border: 1px solid var(--bg-gris) !important;
}

/* Estilo para el scrollbar en general */
::-webkit-scrollbar {
    width: 6px;
    /* Ancho del scrollbar */
    height: 6px !important;
}

/* Estilo para el track del scrollbar (la barra gris) */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color de fondo del track */
    border-radius: 5px;
}

/* Estilo para el thumb del scrollbar (la barra deslizante) */
::-webkit-scrollbar-thumb {
    background: var(--color-verde-menta);
    /* Color del thumb */
    border-radius: 5px;
    /* Borde redondeado del thumb */
    height: 20px !important;
    /* Altura del thumb */

}

/* Estilo para el thumb del scrollbar al hacer hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--color-verde-menta);
    /* Cambia el color del thumb al hacer hover */
}

/* Estilo para el botón de incremento/decremento del scrollbar */
::-webkit-scrollbar-button {
    display: none;
    /* Oculta los botones de incremento/decremento */
}

/* Estilo para la esquina entre el track y el thumb del scrollbar */
::-webkit-scrollbar-corner {
    background: transparent;
    /* Color de fondo transparente */
}


/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< CSS PAGINA PRINCIPAL >>>>>>>>>>>>*/
.swal2-xlarge {
    width: 100% !important;
}

.contenedor-logo {
    padding-left: 0px;

}

.logo-empresa img {


    width: 100%;
    height: 70px;
}

.nombre-empresa {
    padding-top: 20px;
    padding: 20px 2px 0px !important;

}

.nombre-empresa a {
    position: relative;
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff !important;
    background-clip: text;
    text-decoration: none;
}

.color-card {
    background-color: var(--bg-color);
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    padding: 0px;
    border-radius: 0.72rem;
    perspective: 1000px;
}

.card-perfil {
    width: 300px;
    height: 300px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 24px 0px;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    padding: 10px;
    border-radius: 0.72rem;
    perspective: 1000px;
}

.imagenDocumento {

    border-radius: 10px;
}

.imagen-perfil {
    width: 100%;
    height: 100%;
    padding: 25px;
    overflow: hidden;
}

.imagen-perfil img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.texto-perfil {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    justify-content: center;
    text-align: center;
    transform: rotateY(90deg); /* Rotación inicial oculta */
    transition: transform 0.5s;
    transform-style: preserve-3d;
    border-radius: 0.72rem;
    padding: 10px;
  }
  
  .rotated {
    transform: rotateY(0deg); /* Rotación cuando se activa */
  }
  
  .btn-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
  }

.texto-perfil h2 {
    box-sizing: border-box;
    color: #1C1C21;
    font-family: Jost, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    margin: 0px 0px 15px;
}

.texto-perfil p {
    box-sizing: border-box;
    color: #444444;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 20px;
    text-align: left;
}

/* .color-card:hover .imagen-perfil {
    transform: rotateY(-90deg);
}

.color-card:hover .texto-perfil {
    transform: rotateY(0deg);
} */

.barra {
    width: 120px !important;
    height: 2.5px;
    /* Altura de la barra */
    background-color: var(--bg-gris);
    /* Color de la barra */
    position: relative;
    border-radius: 1rem;
}

.franja-barra {
    width: 50%;
    /* Anchura de la franja verde */
    height: 100%;
    /* Altura de la franja verde, igual a la barra */
    background-color: var(--color-verde-menta);
    /* Color de la franja verde */
    position: absolute;
    left: 25%;
    /* Posicionamiento relativo a la barra */
    top: 0;
    /* Asegura que la franja verde esté alineada con la barra negra */
}

.PrincipalSvgContainer {

    background-color: var(--bg-white);
    overflow: hidden;
    position: relative;
    display: inline-block;


}


.PrincipalSvgContainer>img {
    width: 100%;
    height: 800px;
    object-fit: contain !important;
    transform: scale(0.9);
    background-color: #FFFFFF;
    opacity: 0.68;
    margin-bottom: -100px;
    margin-top: -10px;
}

.contenedor-fluido {
    background-color: var(--bg-agua);
    padding-top: 70px;
    padding-bottom: 50px;
}

.div-nosotros-content,
.container-tittle>p {
    padding-left: 50px;
    box-sizing: border-box;
    color: #444444;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 19.5px;
    margin: 0px 0px 13px;
    text-align: left;
}

.div-nosotros-content ul li {
    list-style: none;
    line-height: 38.4px !important;
}

.div-nosotros-content ul li::before {
    content: "\2714 ";
    padding-right: 5px;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-verde-menta);
}

.container-tittle {
    justify-content: center !important;
}

.container-tittle>h2 {
    color: #022D38;
    font-family: Jost, sans-serif !important;
    font-weight: 550;
    font-size: 32px;
    line-height: 38.4px;
    margin: 0px 0px 10px;

    position: relative;
    text-align: center;
    text-transform: uppercase;
}

.card-contacto {
    margin-inline-end: 2px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 24px 0px;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    padding: 30px;
    border-top: 3px solid var(--color-verde-menta);
    border-bottom: 3px solid var(--color-verde-menta);
    border-radius: 0.2rem;

}

.card-contacto-info h4 {
    font-size: 22px;
    font-family: Jost, sans-serif;
    font-weight: 600px !important;
    color: #022D38;

}

.card-contacto-info p {
    color: var(--color-verde-menta);
    padding-left: 39px;
    font-size: 14px;
}

.card-contacto div label {
    font-size: 13px;
}

.font-size {
    font-size: 14px;
}

.btn-contacto-enviar {
    background-color: var(--color-verde-menta) !important;
    width: 120px;
    border-radius: 0.52rem !important;
    color: white !important;
    padding: 3px 3px !important;
    font-size: 14px !important;
    float: right !important;

}


.btn-subir {
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 999;
    background: #016c638a;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .card-contacto-correo {
        padding-bottom: 265px !important;
    }

    .btn-contacto-enviar {
        margin-top: 30px;
    }

    .overlay-imagen {
        margin-top: 15vh;
        padding-left: 60px;
        padding-top: 20px;

    }

    .overlay-imagen h1 {
        font-size: 70px;
        line-height: 56px;
        margin: 0px 0px 10px;
    }

    .overlay-imagen h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 28.8px;
        margin: 0px 0px 50px;
    }

    .contenedor-logo {
        padding-left: 55px;
    }

    .steps-section h1 h2 {
        font-weight: 700;

    }

    .banner-Clientes {
        height: 100vh !important;
        background-size: 120% 120%;
        background-repeat: no-repeat;

    }

    .step_text {
        width: 100%;
        float: left;
        font-size: 45px;


        padding-bottom: 6px;
        margin-left: 0px;
    }

    .layout_padding2 {
        padding-top: 100px;
        padding-bottom: 50px;
    }
}

@media screen and (min-width: 320px) and (max-width:767px) {

    .div-nosotros-content {
        padding-inline: 10px !important;
    }

    .contenedor-fluido {
        padding-inline: 0px !important;
    }

    .contenedor {
        padding-inline: 10px !important;
        margin-top: 0px !important;
        padding-top: 23px !important;

    }

    .contenedor div .p-content {
        padding-inline: 10px;

    }

    .div-end-footer {
        text-align: center;
    }

    .contenedor-perfil .row-perfil {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-inline: 0px !important;
    }
}

@media screen and (min-width: 361px) and (max-width: 374px) {






    .PrincipalSvgContainer>img {
        height: 1210px;
        object-fit: fill !important;
        transform: scale(1.1);
        margin-bottom: -450px;
        margin-top: -320px;
    }

    .overlay-imagen {
        margin-top: 10vh;
        padding-top: 50px;
        padding-right: 10px !important;

    }

    .overlay-imagen h1 {
        font-size: 44px;
        line-height: 56px;
        margin: 0px 0px 0px !important;
        text-align: center !important;
    }

    .overlay-imagen h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 25.8px;
        text-align: center !important;
        background-color: transparent;
        padding-right: 20px;
    }

}

@media screen and (min-width: 320px) and (max-width:359px) {

    .steps-section h1 h2 {
        font-weight: 700;

    }

    .banner-Clientes {
        height: 78vh !important;
        background-size: 120% 140%;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .step_text {
        width: 100%;
        float: left;
        font-size: 20px;


        padding-bottom: 6px;
        margin-left: 0px;
    }

    .layout_padding2 {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .PrincipalSvgContainer>img {
        height: 1200px;
        object-fit: fill !important;
        transform: scale(1.1);
        margin-bottom: -460px;
        margin-top: -350px;
    }

    .overlay-imagen {
        margin-top: 12vh;
        padding-top: 20px;
        padding-right: 10px !important;

    }

    .overlay-imagen h1 {
        font-size: 44px;
        line-height: 56px;
        margin: 0px 0px 0px !important;
        text-align: center !important;
    }

    .overlay-imagen h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 25.8px;
        text-align: center !important;
        background-color: transparent;
        padding-right: 20px;
    }
}

@media screen and (min-width: 360px) and (max-width:430px) {
    .steps-section h1 h2 {
        font-weight: 700;

    }

    .banner-Clientes {
        height: 70vh !important;
        background-size: 100% 110%;
        background-repeat: no-repeat;
        background-position: center -35px;
    }

    .step_text {
        width: 100%;
        float: left;
        font-size: 20px;


        padding-bottom: 6px;
        margin-left: 0px;
    }

    .layout_padding2 {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .PrincipalSvgContainer>img {
        height: 1200px;
        object-fit: fill !important;
        transform: scale(1.1);
        margin-bottom: -428px;
        margin-top: -325px;
    }

    .overlay-imagen {
        margin-top: 9vh;
        padding-top: 20px;
        padding-right: 10px !important;

    }

    .overlay-imagen h1 {
        font-size: 44px;
        line-height: 56px;
        margin: 0px 0px 0px !important;
        text-align: center !important;
    }

    .overlay-imagen h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 25.8px;
        text-align: center !important;
        background-color: transparent;
        padding-right: 20px;
    }
}

@media screen and (min-width: 431px) and (max-width:767px) {
    .steps-section h1 h2 {
        font-weight: 700;

    }

    .banner-Clientes {
        height: 80vh !important;
        background-size: 100% 110%;
        background-repeat: no-repeat;
        background-position: center -20px;
    }

    .step_text {
        width: 100%;
        float: left;
        font-size: 30px;


        padding-bottom: 6px;
        margin-left: 0px;
    }

    .layout_padding2 {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .PrincipalSvgContainer>img {
        height: 1200px;
        object-fit: fill !important;
        transform: scale(0.9);
        margin-bottom: -428px;
        margin-top: -325px;
    }

    .overlay-imagen {
        margin-top: 9vh;
        padding-top: 30px;
        padding-right: 10px !important;

    }

    .overlay-imagen h1 {
        font-size: 44px;
        line-height: 56px;
        margin: 0px 0px 0px !important;
        text-align: center !important;
    }

    .overlay-imagen h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 25.8px;
        text-align: center !important;
        background-color: transparent;
        padding-right: 20px;
    }
}

/*Reposivo Tables*/
@media screen and (min-width: 768px) and (max-width:1023px) {
    .steps-section h1 h2 {
        font-weight: 700;

    }

    .banner-Clientes {
        height: 85vh !important;
        background-size: 100% 120%;
        background-repeat: no-repeat;
        background-position: center -30px;
    }


    .contenedor {
        
        margin-top: 40px !important;
        padding-top: 30px !important;

    }

    .step_text {
        width: 100%;
        float: left;
        font-size: 30px;


        padding-bottom: 6px;
        margin-left: 0px;
    }

    .layout_padding2 {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .contenedor-logo {
        padding-left: 0px;
    }

    .PrincipalSvgContainer>img {
        height: 1200px;
        object-fit: fill !important;
        transform: scale(1.1);
        margin-bottom: -245px;
        margin-top: -125px;
    }

    .overlay-imagen {
        margin-top: 9vh;
        padding-left: 20px;
        padding-top: 30px;

    }

    .overlay-imagen h1 {
        font-size: 44px;
        line-height: 56px;
        margin: 0px 0px 10px;
    }

    .overlay-imagen h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20.8px;
        margin: 0px 0px 50px;
    }

    .contenedor-perfil .row-perfil {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width : 1024px)and (max-width :1280px) {
    .steps-section h1 h2 {
        font-weight: 700;

    }

    .banner-Clientes {
        height: 90vh !important;
        background-size: 100% 110%;
        background-repeat: no-repeat;

    }

    .step_text {
        width: 100%;
        float: left;
        font-size: 30px;


        padding-bottom: 6px;
        margin-left: 0px;
    }

    .layout_padding2 {
        padding-top: 100px;
        padding-bottom: 50px;
    }
}

.form-floating input {
    border-radius: 0.72rem;
    border-color: var(--bg-general);
}


/*==================================================            MODULO DESARROLLADORES                ==================================================*/
.FormGroup {
    padding: 5px;
}

.showContainer {
    display: block;
}

.hiddenContainer {
    display: none;
}

.content-instituciones {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.no-instituciones {
    opacity: 0;
    height: 0;
}

.instituciones {
    opacity: 1;

}

.fixed {
    width: 225px !important;
    height: 100px !important;
    padding: 5px;

    margin-right: 10px !important;
    position: fixed;
    z-index: 1;
    top: calc(100% - 91%);
    left: calc(100vw - 170px);
    display: block;

}


/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/*==================================================            NAVEGACION                 ==================================================*/
.breadcrumb {
    margin-bottom: 0px !important;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/*==================================================            MODULO INSTITUCIONES                ==================================================*/
.img-fluid {
    width: 50px !important;
    height: 40px !important;
    ;


}

.total {

    justify-content: center;
    align-items: center;
    margin-top: auto !important;
    ;
}

.ImageInstitucion {

    height: 400px;
    width: 400px;
    object-fit: contain;
    transition: all 0.3s ease-in-out;
}

.ImageInstitucion:hover {
    transform: scale(1.1);


}

.NombreInstitucion {
    font-family: 'Roboto', sans-serif;
    font-size: 32px !important;
    color: var(--bg-general);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
    text-shadow: 3px 1px 3px #3498db96;
    transition: all 0.3s ease-in-out;
    padding-bottom: 10px;
}

/* .NombreInstitucion:hover {
    transform: scale(1.05);
    color: #3498db;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
} */
.LogoInstitucion {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: scale-down;
}

.mr-5 {
    margin-right: 40px;
}

.pr-2 {
    margin-right: 10px;
}

.pr-3 {
    margin-top: 2px;
    margin-right: 10px;
}

.pt-1 {
    padding-top: 6px !important;
}

@media screen and (min-width: 1367px) {
    .pr-2 {
        margin-right: auto;
    }
}

@media screen and (min-width: 1701px) {
    .pr-2 {
        margin-right: 100px;
    }
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/


/*==================================================            CSS LOGIN                  ==================================================*/
#staticBackdrop {
    border-top-left-radius: 0.72rem;
    border-bottom-left-radius: 0.72rem;
}

.cero-pading-margin {
    margin: 0px;
    padding: 0px;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    height: 76.4px;
    background-color: var(--bg-general);


}

.fixed-botton {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

}

.contenedor {
    padding-top: 70px;
    background-color: var(--bg-color);
    margin-top: 50px;
    background-color: var(--bg-Principal-color);
    min-height: 50vh;
    background-color: var(--bg-white);
    padding-inline: 55px;
}

.bg-nav {
    background-color: var(--bg-general);
    padding-inline: 1vw !important;
}

.overlay-imagen {

    position: absolute;
    width: 100%;
    z-index: 2;
}

.overlay-imagen h1 {
    box-sizing: border-box;
    color: #022D38;
    font-family: Jost, sans-serif;
    font-weight: 500;
    text-align: left;
    text-shadow: 3px 3px 6px rgba(98, 95, 95, 0.7);
}

.overlay-imagen h2 {
    box-sizing: border-box;
    color: #626262;
    text-shadow: 3px 3px 6px rgba(154, 152, 152, 0.7);
    font-family: Jost, sans-serif;
    font-weight: 600;
    text-align: left;
}

.sigae-app {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    background-image: var(--texto-blanco);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 5px;
}

.Encabesado {
    position: absolute;
    width: 350px;
    height: 350px;
    top: 19%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

}

.ajustarLogin {
    padding: 40px 40px;
    border: 2px solid var(--border-Grey-2);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 25px;

}

.offcanvas {
    background-color: var(--bg-Principal-color);


}

.btn-close {
    --bs-btn-close-color: #040404;
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) !important;
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 0.75;
    --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
    --bs-btn-close-white-filter: invert(1) grayscale(-13%) brightness(-65%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: var(--bs-btn-close-color);
    background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: var(--bs-btn-close-opacity);
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: 100% !important;
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
    padding-left: 33%;
    padding-right: 33%;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/*==================================================            CSS PERFIL DE USUARIO           ==================================================*/

.FotoPerfil {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.FotoPerfil2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.FotoPerfilExpediente {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-left: 0px !important;
    margin-bottom: 2px !important;
    border: 1px solid #ccc;
    padding: 0px !important;
    border-radius: 50px;
}

.FotoPerfilExpediente .FormGroup {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-left: 0px !important;
    margin-bottom: 2px !important;
    border: 1px solid #ccc;
    padding: 0px !important;
}

.qr {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    margin-left: 0px !important;
    margin-bottom: 0px !important;

    padding: 0px !important;
    border-radius: 5px;

}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/*==================================================            CSS TABS          ==================================================*/
.borde-abajo {
    border-bottom: 1.5px solid var(--bg-accordion-Hover) !important;
    width: 100% !important;
}

.filaColor {
    background-color: var(--bg-accordion-Hover) !important;
}

.tabs_ {
    background-color: var(--bg-accordion-Hover) !important;
    margin-bottom: 1px !important;
    font-size: 13px !important;
    padding: 0px !important;


}

.border-bottom1 {
    border-bottom: 1px solid var(--bg-Tabs-border) !important;
}

.react-tabs__tab--selected {
    background: var(--bg-th) !important;
    border-color: #aaa !important;
    color: black;
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab:focus {
    outline: none;
}

.tabPanel {
    overflow: auto !important;
}

.cont {
    overflow: hidden;
    height: calc(100vh - 200px);
    /* Altura fija */

    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */

}

.contenedorTabs {
    overflow: hidden;
    height: calc(100vh - 190px);
    /* Altura fija */

    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */

}

.contenedorTabsAsistencia {
    overflow: hidden;
    height: calc(100vh - 240px);
    /* Altura fija */

    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */
}

.contenedorTabsAsistenciaEstuduante {
    overflow: hidden;
    height: calc(100vh - 205px);
    /* Altura fija */

    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */
}

.contenedorQR {
    overflow: hidden;
    height: calc(100vh - 110px);
    /* Altura fija */
    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */
}

.contenedorTabsComunicado {
    overflow: hidden;
    height: calc(100vh - 154px);
    /* Altura fija */

    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */
}

.contenedorAgenda {
    height: calc(100vh - 114px);
}

.tamañoContComunicados {
    height: 92%;
    overflow: hidden;
}

.tamañoContAsistencia {
    height: 91%;
    overflow: hidden;
}

.tamañoCont_Evaluaciones {
    height: 88% !important;
    overflow: hidden;
}

.contenedorSecciones {
    overflow: hidden;
    height: calc(100vh - 175px);
    /* Altura fija */

    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */

}

.contenedorFuncionarios {
    overflow: hidden;
    height: calc(100vh - 180px);
    /* Altura fija */

    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */

}

.contenedorTabsExpediente {
    overflow: hidden;
    height: calc(100vh - 150px);
    /* Altura fija */

    overflow-y: auto;
    /* Agrega scroll vertical si es necesario */
}

.tamañoError {
    height: calc(100vh - 150px);
}

.tamañoPanelTable {
    max-height: 280px;
    overflow-y: 'auto'

}

.border-red {
    border: 1px solid red;
}

.react-tabs__tab {
    display: inline-block;
    border-top: 1px solid var(--bg-Tabs-border) !important;
    border-left: 1px solid var(--bg-Tabs-border) !important;
    border-right: 1px solid var(--bg-Tabs-border) !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 3px 10px !important;
    cursor: pointer;
    margin-right: 3px !important;
    margin-top: 3px !important;
    margin-left: 2px !important;
}

.bg-tr {
    margin-top: -30px !important;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/



/*==================================================            CSS MENSAJERIA         ==================================================*/
.message-input-container {
    /* background-color: #2C2C2C; */
    border-radius: 8px;
    padding: 1px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;

}

.message-input-container button {
    background-color: transparent;
    border: none;
    color: rgb(5, 5, 5);
    font-size: 20px;
}

.message-input-container input {
    background-color: transparent;
    border: none;
    color: rgb(0, 0, 0);
    width: 100%;

}

.boton-emoji {
    background-color: 'transparent';
    border: 'none';
    color: '#fff';
    padding: 1px;
}

.boton-emoji:hover {
    background-color: 'transparent' !important;
}

.emoji-color {
    color: #282828;
}

.epr_-8ygbw8 {
    padding: 2px !important;
}

.epr_c90x4z {
    display: none !important;
    height: 0px !important;
}

.epr_3z09g4 {
    height: 12px !important;

}

.custom-emoji-picker-container {
    /* Para mostrar el picker en una posición flotante */
    bottom: 50px;
    /* Ajusta según tu diseño */
    left: 10px;
    /* O cambia a right si quieres alinear a la derecha */
    background-color: #1e1e1e;
    /* Fondo oscuro */
    border-radius: 10px;
    /* Bordes redondeados */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra para resaltar */
    z-index: 1000;
    /* Para asegurarte que esté por encima de otros elementos */
}

.custom-emoji-picker-container .emoji-picker-react {
    width: 100%;
    /* Asegura que el picker ocupe todo el espacio del contenedor */
    max-height: 300px;
    /* Fija una altura máxima */
    overflow-y: auto;
    /* Agrega scroll si hay demasiados emojis */
}

.custom-emoji-picker-container .emoji-picker-react .emoji-group::before {
    background-color: #1e1e1e;
    /* Fondo de cada grupo de emojis */
    color: white;
    /* Texto en blanco */
}

.custom-emoji-picker-container .emoji-picker-react .emoji-categories {
    background-color: #292929;
    /* Fondo de las categorías de emojis */
}

.custom-emoji-picker-container .emoji-picker-react .emoji-search {
    background-color: #333;
    /* Fondo del buscador (si lo habilitas) */
    border-color: #444;
    /* Color del borde del buscador */
}

.custom-emoji-picker-container .emoji-picker-react .emoji-category-label {
    color: #fff;
    /* Color de las etiquetas de categoría */
}

.enviados-list {
    max-height: 200px;
    /* Altura máxima de 200px */
    overflow-y: auto;
    /* Activar el scroll vertical */
    list-style-type: none;
    /* Eliminar los puntos de la lista */
    padding: 0;
    /* Eliminar el padding */
    margin: 0;
    /* Eliminar el margin */
    border: 1px solid #ccc;
    /* Borde opcional para la lista */
    border-radius: 4px;
    /* Bordes redondeados opcional */
    background-color: #fff;
    /* Fondo blanco para la lista */
}

.font-enviados {
    font-size: 10.5px !important;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/



/*==================================================            CSS TABLE         ==================================================*/
.tamañoMinimoTabla {
    min-height: 100px !important;
}

.contenedorMedicamentos {
    max-height: 150px;
    overflow-y: auto;
}

.tablaMedicamento th {
    background-color: var(--bg-accordion-Hover) !important;
}

th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: 400 !important;
    font-size: 13px !important;
    text-align: -internal-center;
    unicode-bidi: isolate;
    background-color: var(--bg-th) !important;
    border: 0px solid !important;
    /* color: var(--color-white) !important */
}

.tablebody tr td {
    padding: 5px 5px !important;
}

.tablebody tr:hover td {
    background-color: var(--bg-accordion-Hover) !important;
    /* Cambia el color de fondo al pasar el ratón por encima */
}

Table {
    font-size: 13px !important;
}

.fixed-column50 {
    width: 50px;
    /* Ajusta este valor según sea necesario */
}

.fixed-column100 {
    width: 100px;
    /* Ajusta este valor según sea necesario */
}

.fixed-column350 {
    width: 350px;
    /* Ajusta este valor según sea necesario */
}

.cursor {
    cursor: pointer !important;
    text-decoration: none !important;
    color: var(--color-text);
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/



/*==================================================            CSS Cartas           ==================================================*/

.carta {
    min-height: 320px !important;
}

.card-button-wrapper {
    position: relative;
    margin-top: auto;
    /* Esto alinea el botón al final del contenedor */
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/*==================================================            CSS INPUT          ==================================================*/
.invalid-feedback {
    display: block;
}

.tooltip-inner {
    background-color: #dc3545;
    color: #fff;
}

.tooltip-arrow {
    border-right-color: #dc3545;
}

.textarea {
    border: 1px solid var(--border-Input) !important;
    font-size: 13px !important;
    padding: 4px 12px !important;
}

Input {
    border: 1px solid var(--border-Input) !important;
    font-size: 13px !important;
    padding: 4px 12px !important;
}

.tamañoInput25 {
    width: 25% !important;
}

.tamañoInput50 {
    width: 50% !important;
}

.tamañoInput75 {
    width: 75% !important;
}

.tamañoInput100 {
    width: 100% !important;
}

Input:focus {
    border: 1px solid var(--border-Input-Focus) !important;
}

.input-with-icon {
    position: relative;

}

.NavLink {
    font-size: 13px !important;
}

.input-with-icon input[type="text"] {
    padding-right: 30px;

    /* Ajusta el padding para dejar espacio para el icono */
}

#observacion {
    height: 115px !important;
    font-size: 13px !important;

}

.input-with-icon .filter-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    /* Ajusta la posición del icono a la derecha */
    color: #B8B6B6;
    ;
    /* Establece el color de fondo del icono */
    padding: 1.5px;
    /* Ajusta el relleno del icono */

}

#file {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 2px 6px;
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.custom-file-upload:hover {
    background-color: #e0e0e0;
}

.is-invalid {
    border: 1px solid var(--bg-cancelar) !important;
}

Select {
    border: 1px solid var(--border-Input) !important;
    font-size: 13px !important;
    padding: 3px 10px !important;
}

Select:focus {
    border: 1px solid var(--border-Input-Focus) !important;
}

input[type="checkbox"] {
    margin-top: 6px !important;
    margin-right: 3px !important;
    padding: 6px !important;
}

input[type="radio"] {
    margin-top: 7px !important;
    margin-right: 3px !important;
    padding: 6px !important;
}

.form-label {
    margin-bottom: .1rem !important;
}

/* strong {
    font-size: 13px;
}
text{
    font-size: 13px;
} */
/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/*==================================================            CSS LETRAS         ==================================================*/

.LetraPeq {
    font-size: 10px !important;
}

.negrita {
    font-weight: 500 !important;
}

.titulo {
    border-bottom: 3px solid var(--color-titulo);
    padding-left: 10px;
    padding-top: 5px;
}

.tituloZise {
    font-size: 18px !important;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/*==================================================            CSS BOTONES           ==================================================*/
.accordionBorder {
    border-bottom: 2px solid var(--bg-accordion-Hover);
}

.accordion-button2 button {
    /* background-color: var(--bg-btn-accordion)!important; */
    border-radius: 1px !important;
    transition: background-color 0.4s ease-in-out !important;
    font-size: 13px !important;
}

.accordion-button2-pr-0 button {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.accordion-button2 button:hover {
    background-color: var(--bg-accordion-Hover) !important;

}

.accordion-button2 button:active {
    background-color: var(--bg-accordion-Hover) !important;
}

.fosterAcordion {
    background-color: var(--bg-Foster-accordion) !important;
}

.card-body {
    font-size: 13px !important;
}

.btn-ingresar {
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid white !important;
    width: auto;
    padding: 8px 15px !important;
    color: white !important;
    font-weight: 600;
    border-radius: 1rem !important;
    font-size: 14px !important;

}

.btn-ingresar:focus {
    box-shadow: none !important;
}

.btn-ingresar:active,
.btn-ingresar:hover {
    border-color: var(--color-verde-menta) !important;
}


.btnSuscess {
    background-color: var(--bg-general);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-color: none !important;
    color: var(--color-white);
    width: 100%;
    padding: 7px 10px;
    font-weight: 500;
    border-radius: 0.72rem;
    transition: background-color 0.4s ease-in-out, transform 0.2s ease-in-out;
    border: 0px !important;
}

.btnSuscess:hover {
    transform: scale(1.02);
    background-color: var(--color-verde-menta);
}

.btnFullScreen {
    /* float: right; */
    width: 30px;
    height: 30px !important;
    margin-right: -10px !important;
    margin-top: 0px;
    background-color: var(--bg-general) !important;
    border-color: none !important;
    color: var(--color-white);
    border: none;
    border-radius: 5px 5px 5px 5px !important;
    padding: 0px !important;
    font-size: 12px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s, transform 0.3s;
}

.btnFullScreen:hover {

    background-color: var(--color-verde-menta) !important;
    transform: translateY(-0.1px);
}

.btnMenu {
    float: right;
    width: 35px;
    height: 30px !important;
    margin-right: 0px !important;
    margin-top: 0px;
    background-color: var(--bg-general) !important;
    border-color: none !important;
    color: var(--color-white);
    border: none;
    border-radius: 5px 5px 5px 5px !important;
    padding: 0px !important;
    font-size: 12px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s, transform 0.3s;
}

.btnAceptar {
    background-color: var(--bg-general) !important;
    border-color: none !important;
    color: var(--color-white);
    border: none;
    border-radius: 10px !important;
    padding: 4px 12px !important;
    font-size: 13px !important;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s, transform 0.3s;
}

.btnAceptar:hover {

    background-color: var(--color-verde-menta) !important;
    transform: translateY(-0.7px);
}

.btnCancelar {
    background-color: var(--bg-cancelar) !important;
    border: none !important;
    color: var(--color-white);
    border: none;
    border-radius: 8px !important;
    padding: 4px 12px !important;
    font-size: 13px !important;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s, transform 0.3s;
}

.btnCancelar:hover {

    background-color: var(--bg-canelar-hover) !important;
    transform: translateY(-0.7px);
}

.btn-icon-ver {
    color: var(--bg-btnVer) !important;
}

.btn-editar {
    background-color: var(--bg-btnEditar) !important;

    color: var(--bg-white) !important;

    border: none !important;
    border-radius: 8px !important;
    padding: 2px 6px !important;
    font-size: 13px !important;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s, transform 0.3s;
}

.btn-editar:hover {
    background-color: var(--bg-btnEditar-hover);
    /* Azul más oscuro al pasar el mouse */
    transform: translateY(-0.7px);
}

.btn-editar2 {
    background-color: var(--bg-btnEditar) !important;

    color: var(--bg-white) !important;

    border: none;
    border-radius: 8px !important;
    padding: 2px 15px !important;
    font-size: 13px !important;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s, transform 0.3s;
}

.edi2:hover {
    color: var(--bg-btnEditar-hover) !important;

    transform: translateY(-0.7px);
}

.btn-eliminar {
    background-color: var(--bg-btnEliminar) !important;

    color: var(--bg-white) !important;

    border: none;
    border-radius: 8px !important;
    padding: 2px 6px !important;
    font-size: 13px !important;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s, transform 0.3s;
}

.btn-eliminar:hover {
    background-color: var(--bg-btnEliminar-hover);

    transform: translateY(-0.7px);
}


.btn-Ver {
    background-color: var(--bg-btnVer) !important;

    color: var(--bg-white) !important;

    border: none;
    border-radius: 8px !important;
    padding: 2px 6px !important;
    font-size: 13px !important;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    transition: background-color 0.3s, transform 0.3s;
}

.btn-Ver:hover {
    background-color: var(--bg-btnVer-hover);

    transform: translateY(-0.7px);
}

.ajustarBoton {
    padding-top: 25px !important;
}

.eli2:hover {
    color: var(--bg-btnEliminar-hover) !important;

    transform: translateY(-0.7px);
}

.Asignar:hover {
    color: var(--bg-btnVer-hover) !important;

    transform: translateY(-0.7px);
}

.efecto-transparente {
    background-color: rgba(40, 42, 44, 0.742) !important;
}

.br-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.3) 4px 4px 5px 1px !important;
}

.shadow2-2 {
    box-shadow: rgba(0, 0, 0, 0.154) 1px 1px 2px 0px !important;
    padding-top: 3px !important;
    padding-left: 2px !important;

}

.shadow2 {
    box-shadow: rgba(0, 0, 0, 0.3) 1px 5px 5px 2px;
    border-radius: 10px !important;


}

.shadow2-1 {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 6px 2px !important;
    border-radius: 10px !important;

}

.shadow3 {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px 4px;
    border-radius: 10px !important;

}

.shadow4 {
    box-shadow: 0px 0px 10px 9px rgba(0, 0, 0, 0.15) !important;
}

.shadow5 {
    box-shadow: 0px 1px 8px 5px rgba(0, 0, 0, 0.15) !important;
}

.shadow5-black {
    box-shadow: 0.5px 0.5px 8px 3px rgba(0, 0, 0, 0.65) !important;
    border-radius: 5px;
}

.full {
    width: 100% !important;
}

.espaciador-SM {
    margin-top: 5px !important;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/


/*==================================================            CSS MODAL           ==================================================*/

.ModalBody {
    padding: 0px !important;
}

.mymb-0 {
    margin-bottom: 0px !important;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
/*==================================================            CSS FORM          ==================================================*/

.color-header-form {
    background-color: var(--bg-accordion-Hover) !important;
    padding: 0px !important;
    padding-left: 10px !important;

}

.custom-form-group {
    margin-bottom: 8px !important;
    /* Ajusta este valor según tus necesidades */
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/


/*==================================================            CSS ACCORDION           ==================================================*/

.custom-header {
    background-color: var(--bg-white) !important;
    /* Cambia el color de fondo según lo necesites */
    /* Otros estilos personalizados aquí */
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

/*==================================================            CSS FOOTER           ==================================================*/


.whatsapp {
    color: green;
    transition: color 0.5s ease;
}

.instagram {
    color: rgb(198, 61, 61);
    transition: color 0.5s ease;
}

.facebook {
    color: #1d64cf;
    transition: color 0.5s ease;
}

.whatsapp:hover {
    color: green;
    transition: color 0.5s ease;
}

.instagram:hover {
    color: rgb(198, 61, 61);
    transition: color 0.5s ease;
}

.facebook:hover {
    color: #0A58CA;
    transition: color 0.5s ease;
}

.curveDownColor {
    margin-bottom: -100px;
}

.curveUpColor {
    margin-top: -100px;
    transform: rotate(180deg);
    padding-bottom: 50px;
}

.curveUpColor,
.curveDownColor {
    fill: var(--bg-white);
    stroke: var(--bg-white);
    position: absolute;
    left: 0;
    z-index: 1;
}

.pt-51 {
    padding-top: 6rem !important;
}

.footerr {
    width: 100%;
    height: 360px;


}

.footer-app-name {
    box-sizing: border-box;
    color: #37517E !important;
    font-family: Jost, sans-serif;
    font-size: 28px !important;
    font-weight: 600;
    line-height: 28px;
    margin: 0px 0px 10px;
    padding: 2px 0px;
    text-align: left;
    text-transform: uppercase;
}

.footer-tittle {
    box-sizing: border-box;
    color: #37517E;
    font-family: Jost, sans-serif;
    font-weight: 700;
    line-height: 19.2px;
    margin: 0px 0px 6.5px;
    padding: 0px 0px 12px;
    position: relative;
    font-size: 16px;
}

.div-end-footer {
    background-color: var(--bg-general);
    padding-top: 35px;
    padding-bottom: 15px;
    font-size: 14px;
}

.home-footer {
    background: var(--bg-agua);
}

.home-footer div a {
    text-decoration: none !important;
}

.span-negrita {
    font-weight: 800;
    font-size: 14px !important;
}

.text-Enlaces {
    color: #5E5E5E !important;
    transition: color 0.5s ease;
    box-sizing: border-box;
    font-family: Jost, sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.icon-footer-container a i {
    color: #022D38;
}

.sizeNamefooter {
    width: 250px;
    height: 40px;
}

.bxl-whatsapp .bxl-instagram .bxl-facebook {
    color: rgb(255, 255, 255);
    transition: color 0.5s ease;

}

.bxl-whatsapp:hover {
    color: green;
    transition: color 0.5s ease;
}

.bxl-instagram:hover {
    color: rgb(198, 61, 61);
    transition: color 0.5s ease;
}

.bxl-facebook:hover {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    transition: color 0.5s ease;
}




.ver-div {
    border: 1px solid white;
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/


/* ============================  Floating Botton  =========================================== */
.floating-container {
    position: fixed;
    width: 60px;
    height: 50px;
    top: calc(100% - 91%);
    right: 20px;
    z-index: 99;
    margin: 0px 0px;


}

.float-element :hover::after {

    position: absolute;
    background-color: #000000;
    color: #9e9e9e;

    padding: 0px 5px;
    border-radius: 5px;

}

.floating-container:hover {
    height: 150px;
    width: auto;

}

.floating-container:hover .floating-button {

    -webkit-transform: translatey(0px);
    transform: translatey(0px);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.floating-container:hover .element-container .float-element:nth-child(1) {
    -webkit-animation: come-in 0.2s forwards 0.2s;
    animation: come-in 0.2s forwards 0.2s;
}

.floating-container:hover .element-container .float-element:nth-child(2) {
    -webkit-animation: come-in 0.2s forwards 0.4s;
    animation: come-in 0.2s forwards 0.4s;
}

.floating-container:hover .element-container .float-element:nth-child(3) {
    -webkit-animation: come-in 0.2s forwards 0.6s;
    animation: come-in 0.2s forwards 0.6s;
}

.floating-container:hover .element-container .float-element:nth-child(4) {
    -webkit-animation: come-in 0.2s forwards 0.6s;
    animation: come-in 0.2s forwards 0.6s;
}

.floating-container .floating-button {
    position: absolute;
    width: 45px;
    height: 45px;
    background: var(--bg-general);
    bottom: 0;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    color: white;

    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    z-index: 1;

    cursor: pointer;

}

.floating-container .floating-button svg {
    fill: white;
    /* Color del icono */
}

.floating-container .float-element {
    position: relative;
    display: block;
    border-radius: 50%;
    width: 100%;

    margin: 15px auto;
    color: white;
    padding-left: 5px !important;
    padding-right: 5px !important;
    z-index: 0;
    opacity: 0;

}

.floating-container.show {
    display: block;
}

.floating-container .float-element .material-icons {
    vertical-align: middle;
    font-size: 16px;
}

.floating-container .float-element:nth-child(1) {
    background: #42A5F5;

}

.floating-container .float-element:nth-child(2) {
    background: #4CAF50;

}

.floating-container .float-element:nth-child(3) {
    background: #FF9800;

}

.floating-container .float-element:nth-child(4) {
    background: #FF9800;

}

@-webkit-keyframes come-in {
    0% {
        -webkit-transform: translatey(100px);
        transform: translatey(100px);
        opacity: 0;
    }

    30% {
        -webkit-transform: translateX(-50px) scale(0.4);
        transform: translateX(-50px) scale(0.4);
    }

    70% {
        -webkit-transform: translateX(0px) scale(1.2);
        transform: translateX(0px) scale(1.2);
    }

    100% {
        -webkit-transform: translatey(0px) scale(1);
        transform: translatey(0px) scale(1);
        opacity: 1;
    }
}

@keyframes come-in {
    0% {
        -webkit-transform: translatey(100px);
        transform: translatey(100px);
        opacity: 0;
    }

    30% {
        -webkit-transform: translateX(-50px) scale(0.4);
        transform: translateX(-50px) scale(0.4);
    }

    70% {
        -webkit-transform: translateX(0px) scale(1.2);
        transform: translateX(0px) scale(1.2);
    }

    100% {
        -webkit-transform: translatey(0px) scale(1);
        transform: translatey(0px) scale(1);
        opacity: 1;
    }
}














@media screen and (min-width: 320px) and (max-width:767px) {

    .fixed {
        width: 100% !important;
        height: 50px !important;
        padding: 5px;

        margin-right: 10px !important;
        position: fixed;
        z-index: 1;
        top: calc(100% - 89%);
        left: calc(100vw - 170px);


    }

    .espaciador-SM {
        padding: 10px !important;
        display: none !important;
    }

    .btn-editar {
        font-size: 13px !important;
        padding: 6px 10px !important;
    }

    .btn-eliminar {
        font-size: 13px !important;
        padding: 6px 10px !important;
    }

    .btn-Ver {
        font-size: 13px !important;
        padding: 6px 10px !important;
    }

    .Mymt-5 {
        margin-top: 3rem !important;
    }

    .Mymt-1 {
        margin-top: 0.5rem !important;
    }

    .btnFullScreen {
        display: none !important;
    }


    .ImageInstitucion {

        height: 300px;
        width: 250px;
        object-fit: contain;
        transition: all 0.3s ease-in-out;
    }

    .NombreInstitucion {

        font-size: 20px;
        /* Tamaño del texto */



        letter-spacing: 2px;
        /* Espaciado entre letras */



    }
}

@media screen and (min-width: 768px) {}

@media screen and (max-height: 750px) {
    .alfinal {
        position: unset;
        bottom: 0;
        margin-bottom: 0px;

    }
}


/* Media query para dispositivos con ancho entre 300px y 525px */
@media screen and (min-width: 300px) and (max-width: 525px) {


    .fixed-column50 {
        width: 50px;
        /* Ajusta este valor según sea necesario */
    }

    .fixed-column100 {
        width: 100px;
        /* Ajusta este valor según sea necesario */
    }

    .fixed-column350 {
        width: 700px !important;
        /* Ajusta este valor según sea necesario */
    }

    .Divcontenedor {
        height: 94vh;

    }

    .NameNav {
        display: none !important;
    }

    .sidebar {
        position: absolute;
        z-index: 999999;
        margin-top: 0px;
        min-width: 30vw !important;
        height: calc(100vh - 44px);
    }

    .ocultarbtnMenu {
        display: none !important;
    }

    .toggle {
        position: absolute;
    }

    .btnFullScreen {
        display: none !important;
    }

    .Mymt-5 {
        margin-top: 0.1rem !important;
    }

    form {
        padding: 0px !important;
    }

    .cont {
        height: calc(100vh - 34.5vh);
    }

    .mt4 {
        margin-top: 4rem !important;
    }

    .contenedorTabsExpediente {
        height: calc(100vh - 24.5vh);
        /* Altura fija */
    }

    .ajustarBoton {
        padding-top: 0px !important;
    }

    .contenedorTabs {
        height: calc(100vh - 34vh) !important;
    }

    .contenedorTabsAsistencia {
        height: calc(100vh - 41vh) !important;
    }

    .contenedorTabsAsistenciaEstuduante {
        height: calc(100vh - 41vh) !important;
    }

    .contenedorTabsComunicado {
        height: calc(100vh - 24vh) !important;
    }

    .contenedorQR {

        height: calc(100vh - 20vh);
        /* Altura fija */

    }

    .contenedorSecciones {
        height: calc(100vh - 29vh) !important;
    }

    .contenedorFuncionarios {

        height: calc(100vh - 28vh);
        /* Altura fija */
    }

    .content {
        border-top-left-radius: 0px !important;
    }

    .offcanvas.offcanvas-end {
        top: 0;
        right: 0;
        width: 100% !important;
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%);
        padding-left: 1%;
        padding-right: 1%;
    }
}

/* Media query para dispositivos con ancho entre 526px y 765px */
@media screen and (min-width: 526px) and (max-width: 767px) {
    .Divcontenedor {
        height: 93vh;

    }

    .NameNav {
        display: none !important;
    }

    .sidebar {
        position: absolute;
        z-index: 999999;
        margin-top: 0px;
        min-width: 30vw !important;
        height: calc(100vh - 44px);
    }

    .ocultarbtnMenu {
        display: none !important;
    }

    .toggle {
        position: absolute;
    }

    .btnFullScreen {
        display: none !important;
    }

    .Mymt-5 {
        margin-top: 0.2rem !important;
    }

    form {
        padding: 0px !important;
    }

    .cont {
        height: calc(100vh - 30vh);
    }

    .mt4 {
        margin-top: 4rem !important;
    }

    .contenedorTabsExpediente {
        height: calc(100vh - 22vh);
        /* Altura fija */
    }

    .ajustarBoton {
        padding-top: 0px !important;
    }

    .contenedorTabs {
        height: calc(100vh - 26.8vh) !important;
    }

    .contenedorTabsAsistencia {
        height: calc(100vh - 25vh) !important;
    }

    .contenedorTabsAsistenciaEstuduante {
        height: calc(100vh - 25vh) !important;
    }

    .contenedorTabsComunicado {
        height: calc(100vh - 24vh) !important;
    }

    .contenedorQR {
        height: calc(100vh - 16vh);
        /* Altura fija */
    }

    .contenedorSecciones {
        height: calc(100vh - 35vh) !important;
    }

    .contenedorFuncionarios {

        height: calc(100vh - 32vh);
        /* Altura fija */
    }
}

/* Media query para dispositivos con ancho entre 768px y 1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .Divcontenedor {
        height: 93vh;

    }

    .NameNav {
        display: none !important;
    }

    .sidebar {
        position: absolute;
        z-index: 999999;
        margin-top: 0px;
        min-width: 30vw !important;
        height: calc(100vh - 44px);
    }

    .ocultarbtnMenu {
        display: none !important;
    }

    .toggle {
        position: absolute;
    }

    .btnFullScreen {
        display: none !important;
    }

    .Mymt-5 {
        margin-top: 0.1rem !important;
    }

    form {
        padding: 0px !important;
    }

    .cont {
        height: calc(100vh - 30vh);
    }

    .mt4 {
        margin-top: 4rem !important;
    }

    .contenedorTabsExpediente {
        height: calc(100vh - 18vh);
        /* Altura fija */
    }

    .ajustarBoton {
        padding-top: 0px !important;
    }

    .contenedorTabs {
        height: calc(100vh - 25vh) !important;
    }

    .contenedorTabsAsistencia {
        height: calc(100vh - 27vh) !important;
    }

    .contenedorTabsAsistenciaEstuduante {
        height: calc(100vh - 28vh) !important;
    }

    .contenedorTabsComunicado {
        height: calc(100vh - 18vh) !important;
    }

    .contenedorQR {
        height: calc(100vh - 12vh);
        /* Altura fija */
    }

    .contenedorSecciones {
        height: calc(100vh - 25vh) !important;
    }

    .contenedorFuncionarios {

        height: calc(100vh - 22vh);
        /* Altura fija */
    }

    .offcanvas.offcanvas-end {
        top: 0;
        right: 0;
        width: 100% !important;
        border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
        transform: translateX(100%);
        padding-left: 20%;
        padding-right: 20%;
    }
}



@keyframes show-navbar-dropdown {
    0% {
        transform: translate(0, 10px) perspective(200px);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<ANIMACIONES DE CARGA>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
    /* Esto centra verticalmente */
}

.loader-container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
    /* Esto centra verticalmente */
}

.loader {
    border: 10px solid #f3f3f3;
    border-top: 10px solid var(--color-verde-menta);
    border-radius: 50%;
    width: 80px;
    /* Tamaño más grande */
    height: 80px;
    /* Tamaño más grande */
    animation: loader 0.9s linear infinite;
}

.loading-text {
    margin-top: 20px;
    /* Espacio entre la animación y el texto */
    font-size: 30px;
    /* Tamaño de texto más grande */
}

/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
.css-18unl23 {

    background-color: rgba(255, 255, 255, 0) !important;

}

.css-16jesut>.ps-menu-button:hover {
    background-color: rgba(255, 255, 255, 0) !important;
}


/* Archivo de estilo CSS */

.scan-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid transparent;
    border-radius: 8px;
    width: 100%;
    height: 97.5%;
    /* Ajustar altura del área de escaneo */
    animation: border-pulse 2s infinite;
    margin-bottom: 10px;
}

@keyframes border-pulse {
    0% {
        border-color: transparent;
    }

    50% {
        border-color: #081831;
    }

    100% {
        border-color: transparent;
    }
}

.scan-line {
    position: absolute;
    top: 0;
    width: 100%;
    height: 4px;
    background: #081831;
    animation: scan 4s linear infinite;
}

@keyframes scan {
    0% {
        top: 0;
    }

    50% {
        top: 100%;
    }

    96% {
        top: 0;
    }
}

.scan-text {
    font-size: 1.2rem;
    color: #ffffff;
    animation: blink 1.5s steps(1) infinite;
    text-align: center;
    margin-top: 20px;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}